import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login.component';
import { FormsModule } from '@angular/forms';
import { CommonServiceLayerService } from '../services/common-service-layer.service';
import { SharedModule } from '../shared/shared.module';
import { MapLoaderModule } from '../shared/map-loader/map-loader.module';
import { UserLoginComponent } from './user-login/user-login.component';
import { ManualComplaintsComponent } from './manual-complaints/manual-complaints.component';
import { TrackComplaintsComponent } from './track-complaints/track-complaints.component';
import { TableModule } from '../shared/table/table.module';
import { CaaqmsReportDownloaderComponent } from './caaqms-report-downloader/caaqms-report-downloader.component';
import { SharedFilterModalModule } from 'src/app/shared/shared-filter-modal/shared-filter-modal.module';
import { AqiPortalDataComponent } from './aqi-portal-data/aqi-portal-data.component';
import { AgmCoreModule } from '@agm/core';
import { AgmOverlays } from 'agm-overlays';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxEchartsModule } from 'ngx-echarts';
import { NgSelectModule } from '@ng-select/ng-select';
import { ComplaintTrackerComponent } from './complaint-tracker/complaint-tracker.component';

@NgModule({
  declarations: [LoginComponent, UserLoginComponent, ManualComplaintsComponent, TrackComplaintsComponent, CaaqmsReportDownloaderComponent, AqiPortalDataComponent, ComplaintTrackerComponent],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    MapLoaderModule,
    TableModule,
    SharedFilterModalModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAXOgUwOOkwUy8mUPpRhOkR6JGRGW6PKao'
    }),
    AgmOverlays,
    NgbModule,
    NgxEchartsModule,
    NgSelectModule,
  ],
  exports: [LoginComponent],
  providers: [CommonServiceLayerService]
})
export class LoginModule { }
