
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalVariablesService {

  constructor() { }
  public avgChartColors = [
    '#663300',
    '#cc3300',
    '#ff0000',
    '#990033',
    '#990099',
    '#005266',
    '#bf40bf',
    '#996633',
    '#ffff00',
    '#ff6600',
    '#cc0066',
    '#99ff33',
    '#ff9999',
    '#00ffff',
    '#339966',
    '#669999',
    '#666699',
    '#bf80ff',
    '#b3c6ff',
    '#99ffdd',
    '#004d33'
  ];
  public FILTER_FORM_MAPPING = {
    reportTypes: [
      { label: 'Exceedance Report', key: 'exceedenceReport' },
      { label: 'Exceedance Count Report', key: 'exceedanceCount' },
      { label: 'Station Wise Report', key: 'stationWiseReport' },
      { label: 'Parameter Summary Report', key: 'parameterSummaryReport' },
      { label: 'Data Availability Report', key: 'dataAvailabilityReport' },
      { label: 'Data Availability Day Wise Report', key: 'dataAvailabilityDayWiseReport' },
      { label: 'Histogram Report', key: 'histogramReport' },
      { label: 'AQI Monthly Calender View', key: 'month' },
      { label: 'Percentile Report', key: 'percentileReport' },
      { label: 'Scatter Plot Report', key: 'scatterPlotReport' },
      { label: 'Uptime Report', key: 'upTimeReport' },
      { label: 'Windrose Polarised Report', key: 'polarReport' },
      { label: 'AQI Data Report', key: 'aqiDataReport' },
      { label: 'Windrose Report', key: 'windRoseReport' },
      { label: 'AQI Level Report', key: 'aqiLevelReport' },
      { label: 'Manage Complaints', key: 'manageComplaints' },
      { label: 'List Site Parameter', key: 'listSiteParameter' },
      { label: 'Meteorological Report', key: 'meteorologicalReport' },
      { label: 'Meteorological Report', key: 'metrologicalReport' },
      { label: 'Average Summary Report', key: 'averageSummaryReport' },
      { label: 'Averagy Report Loader', key: 'averageReportLoader' },
      { label: 'Shift Report', key: 'shiftReport' },
      { label: 'Alert Setup', key: 'alertSetup' },
      { label: 'Collector Setup', key: 'collectorSetup' },
      { label: 'Monitoring Station Setup', key: 'monitoringStationSetup' },
      { label: 'Parameter Setup', key: 'parameterSetup' },
      { label: 'Site Configuration', key: 'siteConfiguration' },
      { label: 'Exceedence Report', key: 'alarmReport' },
      { label: 'Complaints Inspection', key: 'complaintsInspection' },
      { label: 'Quality Control Charts', key: 'qualityControlCharts' },
      { label: 'Calibration Logs', key: 'calibrationLogs' },
      { label: 'AQI Parameter Report', key: 'aqiParameterReport' },
      { label: 'AQI Trends', key: 'aqiTrends' }
    ],
    schduledReportTypes: [
      { label: 'Average Report', key: 'averageReportLoader' },
      { label: 'Average Summary Report', key: 'averageSummaryReport' },
      { label: 'Exceedance Report', key: 'exceedenceReport' },
      { label: 'Exceedance Count Report', key: 'exceedanceCount' },
      { label: 'Data Availability Report', key: 'dataAvailabilityReport' },
      { label: 'Data Availability Day Wise Report', key: 'dataAvailabilityDayWiseReport' }
    ],
    staticFields: {
      month: ['time'],
      dayWiseAQI: ['time'],
      histogramReport: ['dateRange', 'station', 'parameter', 'averageInterval'],
      percentileReport: ['dateRange', 'station', 'parameter'],
      scatterPlotReport: ['dateRange'],
      upTimeReport: ['dateRange', 'station'],
      aqiParameterReport: ['calendarRange'],
      aqiTrends: ['dateRange'],
      polarReport: ['dateRange', 'station', 'parameters'],
      aqiDataReport: ['dateRange', 'city', 'standard'],
      windRoseReport: ['dateRange'],
      aqiLevelReport: ['city', 'dateRange', 'standard'],
      manageComplaints: ['calendarRange'],
      listSiteParameter: ['station', 'parameters', 'calendarRange'],
      meteorologicalReport: ['dateRange'],
      metrologicalReport: ['dateRange', 'station'],
      averageSummaryReport: ['dateRange'],
      dataAvailabilityDayWiseReport: ['calendarRange', 'station', 'parameters'],
      dataAvailabilityReport: ['dateRange', 'stations'],
      complaintsInspection: ['dateRange'],
      exceedanceCount: ['dateRange', 'station', 'parameters'],
      exceedenceReport: ['dateRange', 'station', 'parameter', 'standard'],
      parameterSummaryReport: ['dateRange'],
      averageReportLoader: ['dateRange', 'station', 'parameters', 'averageInterval'],
      shiftReport: ['dateRange', 'station', 'parameters'],
      stationWiseReport: ['dateRange', 'station'],
      realtimeDataReport: ['dateRange', 'station'],
      alertSetup: ['station'],
      collectorSetup: ['station'],
      monitoringStationSetup: ['station'],
      parameterSetup: ['station'],
      siteConfiguration: ['station'],
      alarmReport: ['dateRange', 'stations', 'parameters'],
      maintenanceSetup: ['station'],
      systemStatusReport: ['station', 'dateRange'],
      operatingHours: ['station', 'dateRange'],
      diagnosticParameter: ['station', 'dateRange'],
      diagnostics: ['station', 'dateRange', 'parameter'],
      calibrationSetup: ['station'],
      calibrationLogs: ['station', 'dateRange', 'parameter'],
      qualityControlCharts: ['station', 'parameter', 'dateRange'],
      aqiDataEntryList: ['dateRange'],
      feedbackPage: ['calendarRange'],
    },
    formJsonExceptions: [
      'parameterSummaryReport',
      'dataAvailabilityReport',
      'aqiDataReport',
      'aqiLevelReport',
      'manageComplaints',
      'siteConfiguration',
      'alertSetup'
    ],
    dateTimeFilters: ['dateRange', 'calendarRange', 'timeRange', 'singleDate', 'singleCalendar', 'singleTime']
  };
}
