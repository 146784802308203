export const environment = {
  production: false,
  API_END_POINT: '/glens-ccr/kl/ui/',
  COMPLAINT_API_END_POINT: '/complaints/kl/GLensClient/',
  AQI_API_END_POINT: '/aqi_service/',
  dashBoardType: 'fujairah',
  // AERMOD_API_END_POINT: 'http://192.168.0.217:8789/',
  AERMOD_API_END_POINT: 'http://192.168.1.95:5775/',
  MQTT_CONNECTION: {
    host: '192.168.1.171',
    port: 9001,
    path: ''
  }
};
// dashboard types are epda, fujairah, default
