import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedExceedenceReportComponent } from './shared-exceedence-report.component';
import { TableModule } from '../table/table.module';
import { SharedModule } from '../shared.module';
import { SharedFilterModalModule } from '../shared-filter-modal/shared-filter-modal.module';


@NgModule({
  declarations: [SharedExceedenceReportComponent],
  imports: [
    CommonModule,
    TableModule,
    SharedModule,
    SharedFilterModalModule
  ],
  exports: [SharedExceedenceReportComponent],
})
export class SharedExccedenceModule { }
