import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-site-header',
  templateUrl: './site-header.component.html',
  styleUrls: ['./site-header.component.scss']
})
export class SiteHeaderComponent implements OnInit {
  @Input() data;
  public internetConnected = navigator.onLine;
  constructor() { }

  ngOnInit() {
    // Config.logger(this.data);
    window.addEventListener('online', () => this.internetConnected = true);
    window.addEventListener('offline', () => this.internetConnected = false);
  }
}
