import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as c3 from 'c3';
import * as d3 from 'd3';
import { Config } from 'src/app/config/config';
import { GlobalVariablesService } from 'src/app/layout/global-variables.service';
import { LayoutService } from 'src/app/layout/layout.service';
import { CommonServiceLayerService } from 'src/app/services/common-service-layer.service';
import { AlertService } from 'src/app/shared/alert.service';
import { ModalFilterData } from 'src/app/shared/shared-filter-modal/filter-modal/filter-modal-interface';
import { SharedService } from 'src/app/shared/shared.service';
import { DataTableConfig } from 'src/app/shared/table/table-interface';

@Component({
  selector: 'app-caaqms-report-downloader',
  templateUrl: './caaqms-report-downloader.component.html',
  styleUrls: ['./caaqms-report-downloader.component.scss']
})
export class CaaqmsReportDownloaderComponent implements OnInit {

  public loginLogo = { logo: '', title: '' };
  public showModal = false;
  public modalData: ModalFilterData;
  public filterData = {};
  public pdfData: any = {
    ReportType: 'CAAQMS Report',
  };
  public tableConfigData: DataTableConfig;
  public responseData;
  public activeTab = 'chart';
  public tabs = [
    { key: 'chart', label: 'Chart' },
    { key: 'table', label: 'Table' }
  ];
  public printMetaData = {
    reportName: 'CAAQMS Report',
    isChart: true,
    fileName: 'CAAQMS Report',
    headerContent: this.pdfData,
    headerLabel: 'value'
  };
  public selectedSite;
  public printDivInfo = {
    id: 'caaqmsReport',
    imageId: 'caaqmsReport',
    imageName: 'CAAQMS Report'
  };
  public showFilters = false;
  public errorStatusMessage;
  public calenderData: any = {
    "columns": "4",
    "disabled": true,
    "hidden": false,
    "identifier": "calendarRange",
    "key": "date",
    "name": "Time Range",
    "pickerType": "calendar",
    "placeholder": "Time Range",
    "required": true,
    "selectMode": "range",
    "type": "date"
  }
  constructor(
    private http: CommonServiceLayerService,
    private router: Router,
    public modalService: LayoutService,
    public sharedService: SharedService,
    public aleret: AlertService,
    public layoutService: LayoutService,
    public globalVariables: GlobalVariablesService
  ) { }

  ngOnInit() {
    this.getStateLogo();
    this.layoutService.setDateFlag(false);
    this.subscribePopStatus();
    this.getFilterData();
    this.setSelectedSite();
  }
  getStateLogo() {
    try {
      this.http.others.getStateMetaData().subscribe(
        response => {
          this.loginLogo = response[window.location.hostname] ? response[window.location.hostname] : response['default'];
          return this.loginLogo;
        });
    } catch (error) {
      Config.logger(error);
    }
  }
  goToLogin() {
    this.router.navigate(['/login']);
  }
  /**
  * show or hide filter modal
  */
  subscribePopStatus() {
    try {
      this.modalService.getToggleModal().subscribe(response => {
        this.showModal = response;
      });
    } catch (error) {
      Config.logger(error);
    }
  }

  /**
   * get filter data from API
   */
  getFilterData() {
    try {
      const payLoad = {
        loadType: 'parameterTrend',
        state: 'Puducherry'
      };
      this.http.reportsModule.AQIFormJson(payLoad).subscribe(
        response => {
          response.data.form.splice(0,0,this.calenderData);
          this.setModalData(response.data.form);
          this.showModal = true;
        });
    } catch (error) {
      Config.logger(error);
    }
  }
  /**
    * show filter modal
    */
  openFilter() {
    try {
      this.showModal = true;
    } catch (error) {
      Config.logger(error);
    }
  }
  /**
  * set selected site data
  */
  setSelectedSite() {
    try {
      this.layoutService.getSiteData().subscribe(response => {
        this.selectedSite = response;
      });
    } catch (error) {
      Config.logger(error);
    }
  }
  /**
   * set modal data
   * @param data filter data
   */
  setModalData(data) {
    try {
      this.modalData = {
        buttons: [{ title: 'Fetch', actionType: 'fetch', class: 'btn btn-primary' }],
        headerTitle: 'CAAQMS Report',
        formData: data,
        inputData: this.filterData,
        staticFields: [],
        width: '450',
        noDynamicFieldWidth: true
      };
    } catch (error) {
      Config.logger(error);
    }
  }

  /**
   * modal action event
   * @param data to get the action type
   */
  modalActionEmitter(data) {
    try {
      if (data.button.actionType === 'fetch') {
        this.showFilters = true;
        this.responseData = undefined;
        //Config.logger(data.payLoad);
        const payLoad = data.payLoad;
        payLoad.from_date = payLoad.fromDate.split(' ')[0];
        payLoad.to_date = payLoad.toDate.split(' ')[0];
        this.pdfData.startTime = payLoad.fromDate;
        this.pdfData.endTime = payLoad.toDate;
        this.pdfData.ReportName = payLoad.siteName;
        this.pdfData = Object.assign(this.pdfData, payLoad);
        this.setTableConfig();
        delete payLoad.time;
        this.http.reportsModule.requestAQIParameterReport(payLoad).subscribe(response => {
          if (response.status) {
            this.responseData = response.data;
            this.formatTableData();
            this.plotChart();
            this.modalService.setToggleModal(false);
            if (payLoad.stationType && payLoad.siteName) {
              this.modalService.setSiteData({ key: payLoad.siteId, label: payLoad.siteName });
            }
            // this.aleret.open('success', 'Message', response.message);
          } else {
            this.sharedService.serviceErrorMessage(response.message);
            this.errorStatusMessage = response.message;
          }
        });
      }
    } catch (error) {
      Config.logger(error);
    }
  }
  /**
   * data table configurations
   */
  setTableConfig() {
    try {
      this.tableConfigData = {
        dtOptions: {
          maxRows: 10,
          searchButton: true,
          columnSearch: false,
          displayOptions: true,
          headerLabel: 'value',
          print: {
            show: true,
            types: ['excel', 'csv', 'html'],
            headerLabel: 'value',
            headerContent: this.pdfData,
            printMetaData: {}
          }
        }
      };
    } catch (error) {
      Config.logger(error);
    }
  }
  /**
   * format data for table
   */
  formatTableData() {
    try {
      const tableData: any = {
        headerContent: this.responseData.headerOrder,
        bodyContent: this.responseData.tabularData
      };
      this.tableConfigData.tableData = tableData;
      // this.tableConfigData.dtOptions.print.headerContent = this.filterData;
    } catch (error) {
      Config.logger(error);
    }
  }
  /**
   * method to plot chart
   */
  plotChart() {
    try {
      const customLegend = [];
      this.responseData.seriesData.columns.forEach(element => {
        customLegend.push(element[0]);
      });
      customLegend.shift();
      const chartOptions: any = {
        bindto: '#caaqmsReport',
        size: {
          height: 400,
        },
        data: this.responseData.seriesData,
        axis: {
          x: {
            type: 'timeseries',
            tick: {
              count: 5,
              format: this.responseData.seriesData.xFormat,
              // rotate: -15,
              // multiline: false,
              // fil: true
            },
            label: {
              text: 'Date and Time',
              position: 'outer-center'
            },
            // height: 60,
            padding: {
              bottom: 0,
            }
          },
          y: {
            tick: {
              count: 5,
              format(x) {
                return x.toFixed(2);
              }
            },
            label: {
              text: 'Reading',
              position: 'outer-middle'
            },
            padding: {
              bottom: 0,
            }

          },

        },
        legend: {
          show: false,
        },
        grid: {
          lines: {
            front: false
          },
          y: {
            show: true,
            color: 'red'
          }
        },
        zoom: {
          enabled: true,
          rescale: true,
          onzoom(domain) { }
        },
      };
      const currentChart = c3.generate(chartOptions);
      d3.select('#caaqmsReport').insert('div', '.chart').attr('class', 'legend').selectAll('span')
        .data(customLegend)
        .enter().append('span')
        .attr('data-id', (id) => {
          return id;
        })
        .html((id) => {
          return id;
        })
        .each(function (id) {
          d3.select(this).style('background-color', currentChart.color(id));
          d3.select(this).style('padding', '3px 10px');
          d3.select(this).style('margin-left', '15px');
          d3.select(this).style('margin-bottom', '5px');
        })
        .on('click', function (d) {
          currentChart.ygrids.remove();
          if ($(this).hasClass('activeLegend')) {
            currentChart.toggle(d);
            currentChart.show();
            console.log("hasClass");
            $(this).removeClass("activeLegend");
            $(this).parent().find('span').removeClass('activeLegend');
            $(this).parent().find('span').removeClass('c3-legend-item-hidden');
            currentChart.ygrids.remove();
            return true;
          } else {
            currentChart.hide();
            currentChart.show(d);
            $(this).parent().find('span').removeClass('activeLegend');
            $(this).parent().find('span').addClass('c3-legend-item-hidden');
            $(this).toggleClass("c3-legend-item-hidden");
            $(this).addClass("activeLegend");
          }
        });
    } catch (error) {
      Config.logger(error);
    }
  }

}
