import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'multiKeysPipe',
  pure: false
})
export class MultiKeysPipePipe implements PipeTransform {
  transform(value, keys: any, term: string): any {
    return value.filter((item) => {
      let existingFlag = false;
      keys.forEach(key => {
        if (!existingFlag) {
          existingFlag = false;
          if (item.hasOwnProperty(key)) {
            if (term) {
              if (item[key].toString().toLowerCase().indexOf(term.toLowerCase()) === -1) {
                existingFlag = false;
              } else {
                existingFlag = true;
              }
            } else {
              existingFlag = true;
            }
          } else {
            existingFlag = false;
          }
        }
      });
      return keys.length > 0 ? existingFlag : true;
    });
  }
}
