import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-check-box',
  templateUrl: './check-box.component.html',
  styleUrls: ['./check-box.component.scss']
})
export class CheckBoxComponent implements OnInit {

  @Input() flag;
  @Output() changeFlag: EventEmitter<any>;
  @Input() booleanType: any;
  constructor() {
    this.changeFlag = new EventEmitter<any>();
  }

  ngOnInit() {
  }
  changeStatus(flag) {
    this.changeFlag.emit(flag);
  }
}
