import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-criteria-info-display',
  templateUrl: './criteria-info-display.component.html',
  styleUrls: ['./criteria-info-display.component.scss']
})
export class CriteriaInfoDisplayComponent implements OnInit {
  @Input() text;
  constructor() { }

  ngOnInit() {
  }

}
