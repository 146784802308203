import { Component, OnInit, Input } from '@angular/core';
import { EChartOption } from 'echarts';

@Component({
  selector: 'app-echarts',
  templateUrl: './echarts.component.html',
  styleUrls: ['./echarts.component.scss']
})
export class EChartsComponent implements OnInit {
  @Input() chartOption = {};
  @Input() options: any = {};
  constructor() { }
  ngOnInit() {
  }
}
