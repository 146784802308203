import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PublicAqiMapDashboardComponent } from './public-aqi-map-dashboard/public-aqi-map-dashboard.component';
import { AgmCoreModule } from '@agm/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { SharedFilterModalModule } from 'src/app/shared/shared-filter-modal/shared-filter-modal.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { TableModule } from 'src/app/shared/table/table.module';
import { TimeAgoPipe } from 'time-ago-pipe';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { AgmOverlays } from 'agm-overlays';



@NgModule({
  declarations: [
    PublicAqiMapDashboardComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    SharedFilterModalModule,
    NgSelectModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAXOgUwOOkwUy8mUPpRhOkR6JGRGW6PKao'
    }),
    FormsModule,
    TableModule,
    NgbModule,
    DataTablesModule,
    AgmOverlays
  ],
  exports: [
    PublicAqiMapDashboardComponent
  ]
})
export class MapLoaderModule { }
