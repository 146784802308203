import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-colors-table',
  templateUrl: './colors-table.component.html',
  styleUrls: ['./colors-table.component.scss']
})
export class ColorsTableComponent implements OnInit {
  @Input() tableData;
  @Input() options: any = {};
  public state: any;
  constructor() { }
  ngOnInit() {
    if (localStorage.getItem('state').includes('Puducherry')) {
      this.state = 'Puducherry';
    }
    // Config.logger(this.tableData);
  }

}
