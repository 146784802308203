import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-display-filters-button',
  templateUrl: './display-filters-button.component.html',
  styleUrls: ['./display-filters-button.component.scss']
})
export class DisplayFiltersButtonComponent implements OnInit {
  @Input() flag;
  @Output() changeFlag: EventEmitter<any>;
  constructor() {
    this.changeFlag = new EventEmitter();
  }

  ngOnInit() {
  }
  setFlag() {
    this.flag = !this.flag;
    this.changeFlag.emit(this.flag);
  }

}
