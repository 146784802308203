import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-multiple-headers-table',
  templateUrl: './multiple-headers-table.component.html',
  styleUrls: ['./multiple-headers-table.component.scss']
})
export class MultipleHeadersTableComponent implements OnInit {
  @Input() tablesData = [];
  @Input() siteNames = false;
  constructor() { }

  ngOnInit() {
    // Config.logger(this.tablesData);
  }

}
